<template>
  <div class="page_con">
    <div class="form_wrap">
      <div class="form">
        <ul class="form_ul">
          <li class="form_li">
            <div class="form_ttl">아이디 확인</div>
            <div class="form_in">
              <div class="inline">
                <label class="input">
                  <input
                    type="email"
                    placeholder="이메일 주소를 입력해 주세요."
                    v-model="userId"
                  />
                </label>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="con_btn">
      <div class="btn_wrap">
        <button class="btn" @click="fnNext">확인</button>
        <div class="hint">
          * 카카오, 네이버, 구글 ID 가입 회원은 각 서비스에서 문의해주세요.
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { componentState } from './index.js'

export default {
  name: 'component-find-password-id-check-index',
  setup() {
    const state = componentState()
    return { ...state }
  }
}
</script>

<style scoped></style>
