import { getCurrentInstance, reactive, toRefs } from 'vue'
import { useRouter } from 'vue-router'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const router = useRouter()
  const state = reactive({
    userId: ''
  })
  const fnNext = async () => {
    if (!(await isValid())) return
    await router.push({
      name: 'find-password-auth-type-index',
      params: { email: state.userId }
    })
  }
  const fnCheckUserId = async () => {
    try {
      const params = {}
      params.userId = state.userId
      const res = await proxy.$UserSvc.postCheckUserId(params)
      if (res.resultCode === '0000') {
        return true
      } else {
        return false
      }
    } catch (e) {
      console.error(e)
    }
  }
  const isValid = async () => {
    if (!proxy.$Util.isValidEmail(state.userId)) {
      alert('올바른 이메일 주소를 등록해주세요.')
      return false
    }
    if (!(await fnCheckUserId())) {
      alert('가입되지 않은 이메일 주소 입니다.')
      return false
    }
    return true
  }
  return { ...toRefs(state), fnNext }
}
